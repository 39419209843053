.coolDownBar {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 60px;
  position: relative;
  border: 1px solid #ffeaec;
  border-radius: 5px;
  opacity: 70%;
}

.progress {
  height: 30px;
  padding: 2px;
  background-color: #0983b1;
  border-radius: 5px;
  z-index: 1;
}

.coolDownBar p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  z-index: 2;
}
