@import "./vars.css";

body {
  display: flex;
  color: var(--color-text);
  background-color: var(--color-primary);
  font-family: var(--font-Fredoka);
  font-optical-sizing: auto;
  overflow: none;
  height: 100vh;
  background-image: url(/src/Assets/Icons/background-image.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 50%;
  background-position-y: 100%;
}

.content {
  transition: opacity 0.5s ease;
}

.darkMode {
  opacity: 0.03;
}

@media screen and (max-width: 1000px) {
  body {
    overflow-x: none;
    overflow-y: scroll;
    background-image: none;
  }
}
