@import "../../vars.css";

.formContainer {
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 100%;
  height: 60px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding: 2px;
  height: 30px;
  width: 100%;
  border: none;
  border: 1px solid #ffeaec;
  opacity: 70%;
}

.textInput {
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 15px;
  padding: 10px;
  color: #ffeaec;
}

.defaultPlaceholder::placeholder {
  color: #ffeaec;
  opacity: 70%;
}

.errorPlaceholder::placeholder {
  color: red;
}

.button {
  cursor: pointer;
  background-color: transparent;
  color: #ffeaec;
  font-size: 18px;
  border: none;
  transform: translateY(-10%);
}

.sendIcon:hover {
  color: var(--color-secondary);
}

.charLimit {
  transform: translateY(-25%);
  position: relative;
  width: 100%;
  font-size: 13px;
}

.underSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InputClicked {
  border: 2px solid #0983b1;
  .button {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.termsAndPolicy {
  position: absolute;
  width: 100%;
}

.promptErrorMsg {
  color: red;
  font-size: 11px;
  width: 50%;
}

.borderForm {
  border: 2px solid #fff;
}

.shake {
  border: 2px solid rgb(181, 3, 3);

  animation: shake 0.13s 3;
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(-2px);
  }
}

@media screen and (max-width: 1000px) {
  .formContainer {
    position: absolute;
    width: 100%;
    border-radius: 10px;
    bottom: 8%;
  }

  .textInput {
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  .formContainer {
    position: absolute;
    width: 100%;
    border-radius: 10px;
    bottom: 10%;
  }

  .textInput {
    font-size: 14px;
  }

  .termsAndPolicy {
    display: none;
  }
}
