.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  flex-direction: column;
}

button {
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  border: none;
}

.googleBtn,
.logOut {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  color: black;
  font-weight: 600;
  font-size: 13px;
  background-color: #ffeaec;
  cursor: pointer;
  .icon {
    transform: translateY(-5%);
    font-size: 15px;
  }
}

.googleBtn {
  width: 180px;
  height: 35px;
}

.googleBtn:hover {
  background-color: var(--color-secondary);
  color: white;
}

.logOut {
  background-color: var(--color-secondary);
  color: #ffeaec;
}

@media only screen and (max-width: 830px) {
  .googleBtn,
  .userAvatar {
    gap: 5px;
    font-size: 9px;
    .icon {
      font-size: 11px;
    }
  }
  .googleBtn {
    width: 130px;
    height: 25px;
  }
  .userAvatar {
    transform: translate(-30px, -10%);
  }
}
