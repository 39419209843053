.userAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  color: black;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

@media only screen and (max-width: 830px) {
  .userAvatar {
    width: 25px;
    height: 25px;
    font-size: 9px;
    
  }
}