.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  bottom: 20%;
  right: 0;
  width: 100%;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  img {
    width: 28px;
    height: 28px;
    transform: translateY(2px);
  }
  p {
    font-size: 30px;
    font-weight: 500;
  }
  .exitBtn {
    position: absolute;
    right: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffeaec;
    opacity: 70%;
    border-radius: 15%;
    height: 25px;
    width: 25px;
    button {
      background-color: none;
    }
  }
  .exitBtn:hover {
    opacity: 40%;
  }
}

.content {
  display: flex;
  padding: 10px;
  margin-top: 2%;
}

.leftSideContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.headerLeftSide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  p {
    font-size: 32px;
    font-weight: 500;
  }
}

.btnsOptions {
  text-align: center;
  color: #ffeaec;
  font-size: 13px;
}

.btns {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.termsAndPrivacy {
  display: flex;
  font-size: 12px;
  opacity: 70%;
  a {
    color: #ffeaec;
  }
  a:hover {
    opacity: 40%;
  }
}

.border {
  height: 230px;
  border-right: 1px solid #ffeaec;
  margin: 60px;
  transform: translateY(-22%);
  opacity: 70%;
}

.rightSideContent {
  display: flex;
  flex-direction: column;
  transform: translateY(-3%);
}

.headerRightSide {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.featuresIncluded {
  margin: 0;
}

.featureItem {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
  img {
    width: 15px;
    height: 15px;
  }
  p {
    font-size: 14px;
    color: #ffeaec;
    font-weight: 100;
  }
}

.rightDownBorder {
  margin-top: 7%;
  border-bottom: 1px solid #ffeaec;
  width: 60%;
  transform: translateX(25%);
}

.bottomRightContent {
  font-weight: 500;
  span {
    color: var(--color-secondary);
  }
}

@media screen and (max-width: 830px) {
  .container {
    bottom: 15%;
    padding: 10px;
  }

  .header {
    p,
    img {
      display: none;
    }
    .exitBtn {
      top: -5%;
      right: 30%;
    }
  }

  .content {
    flex-direction: column;
  }

  .leftSideContent {
    margin: 0;
    align-items: center;
    text-align: center;
  }

  .border {
    display: none;
  }

  .rightSideContent {
    margin-top: 20px;
    align-items: center;
    text-align: center;
  }

  .rightDownBorder {
    display: none;
  }

  .bottomRightContent {
    text-align: center;
  }

  .termsAndPrivacy {
    justify-content: center;
    margin-top: 20px;
  }
}

@media screen and (max-width: 500px) {

  .leftSideContent {
    border-bottom: 1px solid #ffeaec;
    padding-bottom: 15px;
    gap: 10px;
  }
  .header {
    p,
    img {
      display: none;
    }
    .exitBtn {
      right: 20%;
    }
  }

  .btns {
    margin: 0;
  }

  .featureItem {
    p {
      font-size: 12px;
    }
  }
}
