body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: hidden;
}

code {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
}


