.sending {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
}

.p {
  transform: translateX(-10%);
}

@media screen and (max-width: 1000px) {
  .sending {
    position: absolute;
    top: 250px;
    transform: translateX(-30%)
  }
}
