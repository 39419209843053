.footerContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
}

footer p {
  font-size: 14px;
}



@media screen and (max-width: 1000px) {
  .footerContent {
    font-size: 11px; 
  }

  .comingSoon {
    font-size: 14px; 
    margin-left: 0;
  }

  .comingSoon::before {
    display: none; 
  }
}
