.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  gap: 30px;
  position: absolute;
  bottom: 20%;
  width: 100%;
}

.leftSide {
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  font-size: 20px;
  gap: 30px;
  .Feedback,
  .ContactUs,
  .SayHi {
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-direction: column;
  }
  .Feedback h1,
  .ContactUs h1,
  .SayHi h1 {
    margin: 0;
    font-size: 36px;
  }
  .Feedback p,
  .ContactUs p,
  .SayHi p {
    text-align: left;
    font-size: 16px;
    color: #ffeaec;
    opacity: 50%;
    margin-top: 10px;
  }
  .bottomMsg {
    margin-top: 20px;
  }
}

.border {
  height: 550px;
  width: 0.5px;
  background-color: rgb(97, 97, 97);
  opacity: 40%;
  margin-left: 10%;
  margin-right: 5%;
}

.rightSide {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  .title {
    font-family: fredoka;
    font-weight: 100;
  }
  .aboveBtnMsg {
    color: #ffeaec;
    font-size: 17px;
    font-family: fredoka;
    font-weight: 100;
  }
  .forms {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border: none;
    .formContainer,
    .input1,
    .input2,
    .textarea {
      background-color: transparent;
      padding: 10px;
      width: 290px;
      border: 1px solid #ffeaec;
      font-family: sans-serif;
      font-size: 14px;
      color: #fff;
      resize: none;
    }
    .shake {
      border: 2px solid rgb(181, 3, 3);
      animation: shake 0.13s 3;
    }
    .formContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      resize: none;
      height: 350px;
      border: none;
      gap: 5px;
    }
    .input2 {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: none;
    background-color: #fff;
    color: black;
    width: 100px;
    border-radius: 15px;
    gap: 10px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .mailSection {
    font-family: sans-serif;
    font-size: 15px;
    color: #ffeaec;
    opacity: 50%;
    a {
      color: #ffeaec;
      text-decoration: none;
    }
  }
}

.textarea {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

strong {
  font-weight: 500;
}

.finalMsg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  h1 {
    margin: 0;
  }
  .checkIcon {
    font-size: 55px;
  }
  p {
    margin-top: 10%;
  }
}

.errorMsg {
  color: red;
  font-size: 12px;
  margin: 0;
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(-2px);
  }
}

@media screen and (max-width: 1000px) {
  .leftSide {
    display: none;
  }

  .border {
    display: none;
  }

  .rightSide {
    position: absolute;
    bottom: -45px;
  }

  .title {
    font-size: 24px;
  }

  .aboveBtnMsg {
    font-size: 14px;
  }

  .forms {
    width: 100%;
    gap: 10px;
  }

  .formContainer,
  .input1,
  .input2,
  .textarea {
    width: 90%;
    font-size: 12px;
  }

  .btn {
    width: 80%;
    font-size: 14px;
  }

  .mailSection {
    font-size: 12px;
  }

  .finalMsg {
    position: absolute;
    top: 250px;
  }
}
