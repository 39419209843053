@import "../../vars.css";

.chordsDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

/* .mouseOver {
  position: absolute;
  top: -30px;
  right: -135px;
  border: 2px solid var(--color-secondary);
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  border-bottom-left-radius: 10px;
  padding: 10px;
  margin-left: 0;
}

.mouseOver span {
  color: #fff;
}

.exclamationMark {
  border: 1px solid rgb(122, 122, 122);
  border-radius: 50%;
  margin-right: 5px;
}

.mouseOver .Link {
  text-decoration: none;
}

.mouseOverContent {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.keyBPM {
  font-size: 20px;
  font-weight: 200;
}

.sliderAudio {
  position: absolute;
  bottom: 30%;
}
@media screen and (max-width: 1000px) {
  .chordsDisplay {
    width: 100%;
  }

  .header {
    font-size: 15px;
    height: auto;
    width: auto;
    gap: 15px;
  }

  .icon,
  .addIcon {
    font-size: 30px;
  }

  .mouseOver {
    top: -35px;
    right: -120px;
    font-size: 14px;
  }

  .mouseOverContent {
    flex-direction: row;
  }

  .keyBPM {
    font-size: 16px;
  }

  .sliderAudio {
    height: auto;
    transform: translateX(-2%);
  }
}
