.chordList {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 80%;
}

.chords {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  width: 100%;
  padding: 10px;
  font-size: 24px;
  
}

@media screen and (max-width: 1000px) {
  .chordList {
    display: flex;
    width: 100%;
  }
  
  .chords {
    width: 80%;
    padding: 0;
  }
}
