.sliderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  --thumb-width: 20px;
  --thumb-height: 20px;
}

.sliderControl {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.range {
  height: 2.5px;
  width: 550px;
  cursor: pointer;
}

.times {
  display: flex;
  align-items: center;
  width: 100%;
}

.timer {
  font-size: 10.5px;
  font-weight: 500;
  color: var(--color-secondary);
}

.buttons {
  transform: translateY(5%);
}

.PlayPauseIcon {
  color: var(--color-secondary);
  font-size: 35px;
  cursor: pointer;
}

.icon:hover {
  opacity: 50%;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: var(--color-secondary);
  padding: 10px;
  height: 15px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.btn:hover {
  opacity: 50%;
}

.btn .downloadIcon,
.btn .saveIcon {
  font-size: 18px;
}

.btn p {
  font-size: 12px;
}

.btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.firstLineBtns {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@media screen and (max-width: 1000px) {
  .sliderContainer {
    gap: 5px;
  }
  .range {
    width: 450px;
  }
  .timer {
    font-size: 8px;
  }
  .PlayPauseIcon {
    font-size: 30px;
  }
  .btn {
    padding: 8px;
    height: 13px;
  }
  .btn .downloadIcon,
  .btn .saveIcon {
    font-size: 16px;
  }
  .btn p {
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  .sliderContainer {
    gap: 3px;
  }
  .range {
    width: 350px;
  }
  .timer {
    font-size: 7px;
  }
  .PlayPauseIcon {
    font-size: 28px;
  }
  .btn {
    padding: 6px;
    height: 12px;
  }
  .btn .downloadIcon,
  .btn .saveIcon {
    font-size: 14px;
  }
  .btn p {
    font-size: 10px;
  }
}

@media screen and (max-width: 500px) {
  .sliderContainer {
    gap: 3px;
  }
  .range {
    width: 250px;
  }
  .timer {
    font-size: 8px;
  }
  .PlayPauseIcon {
    font-size: 25px;
  }
  .btn {
    padding: 5px;
    height: 10px;
  }
  .btn .downloadIcon,
  .btn .saveIcon {
    font-size: 12px;
  }
  .btn p {
    font-size: 9px;
  }
}

@media screen and (max-width: 375px) and (max-height: 500px) {
  .sliderContainer {
    gap: 3px;
  }
  .range {
    width: 200px;
  }
  .timer {
    font-size: 7px;
  }
  .PlayPauseIcon {
    font-size: 22px;
  }
  .btn {
    padding: 4px;
    height: 8px;
  }
  .btn .downloadIcon,
  .btn .saveIcon {
    font-size: 10px;
  }
  .btn p {
    font-size: 8px;
  }
}
