@import "../../vars.css";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 550px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 65%;
}

.leftSide {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 30px;
  .header p {
    font-size: 43px;
    font-weight: 500;
    margin: 0;
  }
  .leftSideContent p {
    font-size: 26px;
    font-weight: 200;
    margin: 0;
  }
  .exampleSection {
    display: flex;
    align-items: center;
    text-align: left;
  }
}

.homepageLoading {
  position: absolute;
  right: 50%;
  top: 50%;
}

.rightSide {
  transform: translateY(5%);
}

.underline {
  position: relative;
}
.underline::after {
  content: "";
  width: 100%;
  height: 5px;
  background-image: url(/src/Assets/Icons/underline.png);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
}

.exampleSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .lampIcon {
    font-size: 35px;
    transform: translateY(10%);
  }
  .exampleContent {
    display: flex;
    flex-direction: column;
    gap: 3px;
    .exampleFirstSection p {
      font-weight: 100;
      font-size: 18px;
      margin: 0;
    }
    .exampleSecondSection p {
      font-weight: 100;
      font-size: 13px;
      color: #ffeaec;
      opacity: 50%;
      margin: 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    height: 100%;
    padding: 20px;
    overflow-x: none;
    overflow-y: none;
    text-decoration: none;
    margin: 0;
  }
  .content {
    flex-direction: column;
    width: 90%;
    .inputContainer {
      position: absolute;
      bottom: 10%;
      width: 40%;
    }
  }
  .leftSide .header p {
    font-size: 36px;
  }
  .leftSide .leftSideContent p {
    font-size: 22px;
  }

  .exampleSection .lampIcon {
    font-size: 30px;
  }
  .exampleSection .exampleContent .exampleFirstSection p {
    font-size: 16px;
  }
  .exampleSection .exampleContent .exampleSecondSection p {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  .content {
    width: 100%;
    .inputContainer {
      position: absolute;
      bottom: 7%;
      width:50%;
    }
  }
  .leftSide .header p {
    font-size: 32px;
  }
  .leftSide .leftSideContent p {
    font-size: 20px;
  }

  .exampleSection .lampIcon {
    font-size: 28px;
  }
  .exampleSection .exampleContent .exampleFirstSection p {
    font-size: 15px;
  }
  .exampleSection .exampleContent .exampleSecondSection p {
    font-size: 11px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    height: auto;
    padding: 15px;
  }
  .content {
    width: 100%;
    .inputContainer {
      position: absolute;
      bottom: 7%;
      width:70%;
    }
  }

  .leftSide {
    margin-top: 3%;
  }

  .leftSide .header p {
    font-size: 28px;
  }
  .leftSide .leftSideContent p {
    font-size: 18px;
  }

  .rightSide {
    transform: none;
  }

  .exampleSection .lampIcon {
    font-size: 25px;
  }
  .exampleSection .exampleContent .exampleFirstSection p {
    font-size: 14px;
  }
  .exampleSection .exampleContent .exampleSecondSection p {
    font-size: 10px;
  }
}

@media screen and (max-width: 400px) {
  .container {
    height: auto;
    padding: 7px;
  }

  .leftSide .header p {
    font-size: 24px;
  }
  .leftSide .leftSideContent p {
    font-size: 16px;
  }

  .rightSide {
    transform: translateY(-5%);
  }

  .exampleSection .lampIcon {
    font-size: 22px;
  }
  .exampleSection .exampleContent .exampleFirstSection p {
    font-size: 13px;
  }
  .exampleSection .exampleContent .exampleSecondSection p {
    font-size: 10px;
  }
}
