.container {
  display: flex;
  flex-direction: row;
  animation: fadeInUp 2s ease-out alternate;
}

.content {
  display: flex;
  font-size: 11px;
  text-align: center;
}

.content {
  opacity: 70%;
}

.content a {
  color: #ffeaec;
}

.content a:hover {
  opacity: 40%;
}

