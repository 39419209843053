@import "../../vars.css";

.chordComposerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: 3%;
}

.inputContainer {
  position: absolute;
  bottom: 18%;
  width: 40%;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media screen and (max-width: 1000px) {
  .chordComposerContainer {
    height: 50vh;
    margin-top: 5%;
    margin-bottom: 15%;
  }

  .inputContainer {
    flex-direction: column;
    margin-top: 10px;
    width: 90%;
  }

  .loading {
    height: 80vh;
  }
}

@media screen and (max-width: 500px) {
  .inputContainer {
    width: 80%;
  }
}
