.chordItem {
  width: 100%;
}

.chordName {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.degree {
  font-size: 15px;
  color: rgb(86, 63, 91);
  text-transform: uppercase;
  margin-top: 5px;
}

@media screen and (max-width: 1000px) {
  .chordName {
    font-size: 14px;
  }

  .degree {
    font-size: 10px;
  }
}
