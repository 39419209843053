@import "../../../vars.css";
.navbarContainer {
  display: flex;
  flex-direction: column;
  margin-left: 7%;
  margin-right: 7%;
  margin-top: 2%;
}

.menuBtn {
  display: none;
}

.menuItems {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  list-style: none;
}

.btns {
  display: flex;
  flex-direction: row-reverse;
}

.border {
  color: #ffeaec;
  opacity: 70%;
  line-height: 30px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightSideNav {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  .signInBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .laterBtn {
    visibility: hidden;
  }
}

.blurRightSide {
  display: flex;
  justify-content: center;
  align-items: center;
  .signInBtn,
  .btns,
  .menuBtn {
    display: none;
  }
}

.laterBtn {
  background-color: transparent;
  color: #ffeaec;
  border: 1px solid #ffeaec;
  width: 120px;
  border-radius: 8px;
  position: absolute;
  top: 35px;
  right: 200px;
  font-weight: 500;
}

.hiddenLaterBtn {
  .laterBtn {
    visibility: hidden;
    .signInBtn,
    .btns {
      visibility: inherit;
    }
  }
}

.leftSideNav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22%;
  gap: 5px;
  .navIcon {
    width: 33px;
    height: 33px;
    transform: translateY(-10%);
  }
  .title {
    font-size: 36px;
    text-decoration: none;
    color: var(--color-text);
    transform: translateY(-15%);
  }
}

@media only screen and (max-width: 1000px) {
  .laterBtn {
    width: 90px;
    top: 20px;
    right: 100px;
  }
}

@media only screen and (max-width: 830px) {
  .navbarContainer {
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 7%;
  }

  .leftSideNav {
    .navIcon {
      width: 25px;
      height: 25px;
    }
    .title {
      font-size: 20px;
    }
  }

  .rightSideNav {
    display: flex;
    flex-direction: column;
  }

  .laterBtn {
    width: 70px;
    top: 20px;
    right: 50px;
  }

  .content {
    flex-direction: row;
    align-items: center;
  }

  .menu {
    position: absolute;
    right: 0;
    margin-right: 3%;
    flex-direction: column;
    align-items: flex-end;
    gap: 11px;
    z-index: 3;
  }

  .menuBtn {
    width: 25px;
    display: block;
    cursor: pointer;
  }

  .menuItems {
    display: none;
    flex-direction: column-reverse;
    align-items: center;
    gap: 13px;
    transform: translateX(-75%);
    border-radius: 10px;
    background: rgb(25, 55, 109);
    background: linear-gradient(
      0deg,
      rgba(25, 55, 109, 0.2) 0%,
      var(--color-primary) 20%
    );
    padding: 24px 18px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    list-style: none;
    .border {
      display: none;
    }
    .btns {
      flex-direction: column;
    }
  }

  .menuOpenStyle {
    position: absolute;
    display: flex;
    z-index: 1;
  }
}

@media only screen and (max-width: 500px) {
  .laterBtn {
    width: 70px;
    top: 10px;
    right: 15px;
  }

  .signInBtn {
    margin-right: 25%;
  }
}
