.container {
  width: 350px;
  height: 450px;
}

.slick-dots li button:before {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "slick";
  color: transparent; 
  position: absolute;
  top: 0;
  left: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 2px solid var(--color-secondary);
  border-radius: 50%;
  width: 9px;
  height: 9px;
  background-color: transparent; 
}

.slick-dots li.slick-active button:before {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary); 
  color:  var(--color-secondary);
}

.card {
  text-align: center;
  color: var(--color-secondary);
}

.cardContent {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 30px;
  background-color: #ffeaec;
  height: 250px;
  border-radius: 10px;
  margin: 10px;
}

.title h3 {
  margin: 0;
}

.keyBpm {
  display: flex;
  gap: 8px;
  font-size: 11px;
  p span {
    font-weight: bold;
    margin: 0;
  }
}

.chordsItem {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.chordPair {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 2px;
}

.degree {
  font-size: 13px;
  opacity: 40%;
}

.sliderAudio {
  
}

@media screen and (max-width: 1000px) {
  .container {
    width: 300px;
    height: 400px;
  }
  .cardContent {
    padding: 25px;
    height: 220px;
  }
  .keyBpm {
    font-size: 10px;
  }
  .degree {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 250px;
    height: 350px;
  }
  .cardContent {
    padding: 20px;
    height: 200px;
  }
  .keyBpm {
    font-size: 9px;
  }
  .degree {
    font-size: 11px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    width: 270px;
    height: 300px;
    transform: translateY(3%);
  }
  .cardContent {
    padding: 12px;
    height: 180px;
  }
  .chordPair {
    font-size: 12px;
  }

  .keyBpm {
    font-size: 8px;
  }
  .degree {
    font-size: 10px;
  }
}

@media screen and (max-width: 375px) and (max-height: 500px) {
  .container {
    width: 180px;
    height: 280px;
    transform: translateY(0);
  }
  .cardContent {
    padding: 10px;
    height: 160px;
  }
  .keyBpm {
    font-size: 7px;
  }
  .degree {
    font-size: 9px;
  }
}
