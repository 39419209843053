.loading {
    display: flex;
    justify-content: center;
    align-items: center;
   
}

@media screen and (max-width: 500px) {
    
}
